.guest-form-wrapper {
    background: #fff;

    div.logout {
        margin-right: 30px;
        text-align: right;
        margin-top: 30px;
    }

    .inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;

        .form-content {
            position: relative;
            text-align: center;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            padding: 60px;
            width: 59%;

            @media all and (max-width:1024px) {
                width: 100% !important;
                padding: 10px !important;
            }

            .form-items {
                border: 3px solid #fff;
                padding: 40px;
                display: inline-block;
                width: 100%;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                text-align: left;
                -webkit-transition: all 0.4s ease;
                transition: all 0.4s ease;
                background-color: #0b0b0b;

                @media all and (max-width:800px) {
                    width: 89%;
                }

                .form-container {
                    .error-message {
                        color: 'red' !important;
                        font-style: 'Italic' !important;
                    }

                    div {
                        #csvFile {
                            color: #0b0b0b
                        }

                        p {
                            font-size: 13px;
                            padding-top: 3px;
                            padding-bottom: 0px;
                            margin: 0px;
                        }

                        .text-danger {
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }

                    .individual-wrapper {
                        grid-gap: 10px;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        @media all and (max-width:1024px) {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }

                    .toggle-btn-wrapper {
                        margin: 20px 0px 10px 0px;

                        span {
                            border: 1px solid #cccccc38;
                            padding: 5px;
                            color: #cccccc38;
                            border-radius: 3px;
                            margin-right: 10px;
                            transition: background-color 1s ease;
                            cursor: pointer;
                            width: 200px !important;
                            font-size: small;

                            &:hover {
                                border: 1px solid #fff;
                                color: #000;
                                background: #fff;
                            }
                        }



                        .active {
                            border: 1px solid #fff;
                            color: #000;
                            background: #fff;
                        }
                    }

                    .download {
                        border: transparent;
                        color: #ddd;
                        background: transparent;
                        cursor: pointer;

                        &:hover {
                            color: blue;
                        }
                    }
                    .submit {
                        @media all and (max-width:1024px) {
                            width: 100%;
                        }
                    }

                }

            }

            h3 {
                color: #fff;
                text-align: left;
                font-size: 28px;
                font-weight: 600;
                margin-bottom: 5px;

                .form-title {
                    margin-bottom: 30px;
                }
            }

            p {
                color: #fff;
                text-align: left;
                font-size: 17px;
                font-weight: 300;
                line-height: 20px;
                margin-bottom: 10px;
            }

            label {
                color: #fff;
            }

        }
    }
}

.logout-btn-wrapper {
    text-align: end;
    margin: 20px 20px 10px 0px;

    .back-btn {
        color: #001723;
        background-color: transparent;
        text-align: left !important;
        position: absolute;
        margin-left: 1rem;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 1rem;
        padding-right: 1rem;
        left: 20px;
    }

    .export-csv {
        text-decoration: none;
        margin: 0px 10px;
        padding: 5px;
        border-radius: 3px;

        &:hover {
            background: #001723;
            color: #fff;
        }
    }

    button {
        background: transparent;
        border: transparent;
        border-radius: 3px;
        padding: 3px;

        &:hover {
            background: #001723;
            color: #fff;
        }
    }

    .table-responsive {
        overflow-x: auto;
    }
}

.react-bs-table-sizePerPage-dropdown {
    float: left !important;
}

.dropdown-menu {
    --bs-dropdown-min-width: none !important;
}

.react-bootstrap-table-pagination {
    margin-top: 1rem !important;
}

.sr-only {
    display: none !important;
}

.bootstrap-table-header {
    font-size: small;

    th {
        width: 15rem !important;
    }
}

.dropdown-toggle::after {
    transform: rotate(180deg) !important;
}

.dropdown-menu {
    position: absolute;
    top: -274px;
}

.App {
    margin: 50px;
    @media all and (max-width:1024px) {
        margin: 0px;
    }
}

.table-wrapper {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px;
}