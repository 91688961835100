.guest-form-wrapper {
  background: #fff;
}

.guest-form-wrapper div.logout {
  margin-right: 30px;
  text-align: right;
  margin-top: 30px;
}

.guest-form-wrapper .inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.guest-form-wrapper .inner-wrapper .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 60px;
  width: 59%;
}

@media all and (max-width: 1024px) {
  .guest-form-wrapper .inner-wrapper .form-content {
    width: 100% !important;
    padding: 10px !important;
  }
}

.guest-form-wrapper .inner-wrapper .form-content .form-items {
  padding: 30px;
  display: inline-block;
  width: 100%;
  border-radius: 1px;
  box-shadow: 5px 5px 20px #555555;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #dddddd;

}

@media all and (max-width: 800px) {
  .guest-form-wrapper .inner-wrapper .form-content .form-items {
    width: 89%;
  }
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .error-message {
  color: 'red' !important;
  font-style: 'Italic' !important;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container p {
  color: #000;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container div #csvFile {
  color: #0b0b0b;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container div p {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 0px;
  margin: 0px;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container div .text-danger {
  font-size: 15px;
  font-weight: 400;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .individual-wrapper {
  grid-gap: 10px;
  display: -ms-grid;
  display: grid;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .toggle-btn-wrapper {
  margin: 20px 0px 10px 0px;
}

html, body, .guest-form-wrapper{
  background-color: #fafafa;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .toggle-btn-wrapper button {
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  color: #474747;
  margin-right: 10px;
  cursor: pointer;
  width: 200px !important;
  font-weight: 400;
  user-select: none;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .toggle-btn-wrapper .active {
  background: #548ee6;
  color: #212121;
  font-weight: 600;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .download {
  border: transparent;
  color: blue;
  background: transparent;
  cursor: pointer;
}

.guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .download:hover {
  color: red;
}

@media all and (max-width: 1024px) {
  .guest-form-wrapper .inner-wrapper .form-content .form-items .form-container .submit {
    width: 100%;
  }
}

.guest-form-wrapper .inner-wrapper .form-content h3 {
  color: #212121;
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.guest-form-wrapper .inner-wrapper .form-content h3 .form-title {
  margin-bottom: 30px;
}

.guest-form-wrapper .inner-wrapper .form-content p {
  color: #fff;
  text-align: left;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 10px;
}

.guest-form-wrapper .inner-wrapper .form-content label {
  color: #212121;
}

.logout-btn-wrapper {
  text-align: end;
  margin: 20px 20px 10px 0px;
}

.logout-btn-wrapper .back-btn {
  color: #001723;
  background-color: transparent;
  text-align: left !important;
  position: absolute;
  margin-left: 1rem;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  left: 20px;
}

.logout-btn-wrapper .export-csv {
  text-decoration: none;
  margin: 0px 10px;
  padding: 5px;
  border-radius: 3px;
}

.logout-btn-wrapper .export-csv:hover {
  background: #001723;
  color: #fff;
}

.logout-btn-wrapper button {
  background: transparent;
  border: transparent;
  border-radius: 3px;
  padding: 3px;
}

.logout-btn-wrapper button:hover {
  background: #001723;
  color: #fff;
}

.logout-btn-wrapper .table-responsive {
  overflow-x: auto;
}

.react-bs-table-sizePerPage-dropdown {
  float: left !important;
}

.dropdown-menu {
  --bs-dropdown-min-width: none !important;
}

.react-bootstrap-table-pagination {
  margin-top: 1rem !important;
}

.sr-only {
  display: none !important;
}

.bootstrap-table-header {
  font-size: small;
}

.bootstrap-table-header th {
  width: 15rem !important;
}

.dropdown-toggle::after {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}

.dropdown-menu {
  position: absolute;
  top: -274px;
}

.App {
  margin: 50px;
}

@media all and (max-width: 1024px) {
  .App {
    margin: 0px;
  }
}

.table-wrapper {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px;
}
